class Sunray {
    constructor($backend) {
        this.$backend = $backend
        this.listWorkstations = this.listWorkstations.bind(this)
        this.importWorkstations = this.importWorkstation.bind(this)
    }
    async getStats(options) {
        let token = await this.$backend.$auth.getTokenSilently()

        return new Promise(resolve => {
            this.$backend.axiosCache
                .get(process.env.VUE_APP_API_SERVER + '/api/v1/sunray/workstation/stats', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        ...options,
                    }
                })
                .then(response => {
                    resolve(response.data)
                })
                .catch((error) => {
                    if (error) console.log(error)
                    resolve()
                })
        })
    }
    async listWorkstations(options) {
        let token = await this.$backend.$auth.getTokenSilently()

        return new Promise((resolve, reject) => {
            this.$backend.axiosCache
                .get(process.env.VUE_APP_API_SERVER + '/api/v1/sunray/workstation/list', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        ...options,
                    },
                    timeout: 30000,
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    if (!error.message.match(/timeout\s.*exceeded/i)) reject(error)
                })
        })
    }
    async importWorkstation(options) {
        let token = await this.$backend.$auth.getTokenSilently()

        return new Promise((resolve, reject) => {
            this.$backend.axiosCache
                .post(process.env.VUE_APP_API_SERVER + '/api/v1/sunray/workstation/import', options, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    async removeTaintedWorkstation(address, vm_id) {
        let token = await this.$backend.$auth.getTokenSilently()

        return new Promise((resolve, reject) => {
            this.$backend.axiosCache
                .post(
                    process.env.VUE_APP_API_SERVER + '/api/v1/sunray/workstation/removeTainted',
                    { address, vm_id },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
    async removeTaintedWorkstationImage(address, image_id) {
        let token = await this.$backend.$auth.getTokenSilently()

        return new Promise((resolve, reject) => {
            this.$backend.axiosCache
                .delete(process.env.VUE_APP_API_SERVER + '/api/v1/sunray/workstation/removeTaintedImage', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    data: { address, image_id },
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    async suspendWorkstation(options) {
        let token = await this.$backend.$auth.getTokenSilently()

        return new Promise((resolve, reject) => {
            this.$backend.axiosCache
                .patch(process.env.VUE_APP_API_SERVER + '/api/v1/sunray/workstation/suspend', options, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    async suspendWorkstationCheck(options) {
        let token = await this.$backend.$auth.getTokenSilently()

        return new Promise((resolve, reject) => {
            this.$backend.axiosCache
                .get(process.env.VUE_APP_API_SERVER + '/api/v1/sunray/workstation/suspend/check', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        ...options,
                    },
                })
                .then((response) => {
                    let job = response.data
                    resolve(job)
                })
                .catch((error) => {
                    if (error.response.status == 404) {
                        resolve({ status: 'unknown' })
                    } else {
                        reject(error)
                    }
                })
        })
    }
    async restoreWorkstation(options) {
        let token = await this.$backend.$auth.getTokenSilently()

        return new Promise((resolve, reject) => {
            this.$backend.axiosCache
                .patch(process.env.VUE_APP_API_SERVER + '/api/v1/sunray/workstation/restore', options, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    async restoreWorkstationCheck(options) {
        /* Add a boolean running attribute to the job status and return it */
        let token = await this.$backend.$auth.getTokenSilently()

        return new Promise((resolve, reject) => {
            this.$backend.axiosCache
                .get(process.env.VUE_APP_API_SERVER + '/api/v1/sunray/workstation/restore/check', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        ...options,
                    },
                })
                .then((response) => {
                    let job = response.data

                    resolve(job)
                })
                .catch((error) => {
                    if (error.response.status == 404) {
                        resolve({ status: 'unknown' })
                    } else {
                        reject(error)
                    }
                })
        })
    }
    async updateProviderApiKey(options) {
        let token = await this.$backend.$auth.getTokenSilently()

        return new Promise((resolve, reject) => {
            this.$backend.axiosCache
                .patch(process.env.VUE_APP_API_SERVER + '/api/v1/sunray/settings/apikey', options, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    if (error.response && error.response.data) reject(error.response.data)
                    else reject(error)
                })
        })
    }
    async getProviderApiKey() {
        let token = await this.$backend.$auth.getTokenSilently()

        return new Promise((resolve, reject) => {
            this.$backend.axiosCache
                .get(process.env.VUE_APP_API_SERVER + '/api/v1/sunray/settings/apikey', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

module.exports = Sunray
