<template>
  <v-app id="">
    <nav-bar />
    <v-main>
      <error/>
      <keep-alive>
        <router-view/>
      </keep-alive>
    </v-main>
    <nav-footer />
  </v-app>
</template>

<script>
  import NavBar from "./components/NavBar";
  import NavFooter from './components/NavFooter';
  import Error from "./components/Error";

  export default {
    components: {
      NavBar,
      NavFooter,
      Error
    }
  }
</script>