<template>
    <span :class="fontClass">
        <span class="font-weight-thin">Brake</span>
        <span class="font-weight-medium text-uppercase yellow--text text--darken-1 font-italic">Code</span>
    </span>
</template>
<script>
export default {
    name: 'stylizedName',
    props: {
        size: String
    },
    computed: {
        fontClass: function() {
            return `${this.size}`;
        }
    }
}
</script>