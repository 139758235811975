import { EventEmitter } from "events";
import axios from "axios";

const axiosCache = axios.create({
    headers: { "Cache-Control": "no-cache" }
}),
    retryInterval = 1000,
    maxRetries = 3;

axiosCache.defaults.raxConfig = {
    instance: axiosCache
};

class DebuggerService extends EventEmitter {
    constructor() {
        super();
    }
    setStore($store) {
        this.$store = $store;
    }
    addSession(options, callback) {
        let self = this;
        let { cid } = options;
        let session = { [cid]: options };

        self.$store.commit('addSession', session);
        this.emit('sessionAdded');
        callback();
    }
    removeSession(cid) {
        this.$store.commit('removeSession', { cid });
    }
    updateSession(cid, getJsonInfo) {
        let self = this;
        if (self.$store.state.sessions[cid]) {
            getJsonInfo(cid)
                .then(jsonInfo => {
                    let newSession = self.$store.state.sessions[cid];
                    newSession.src = newSession.src.replace(newSession.inspectorId, jsonInfo.id);
                    newSession.inspectorId = jsonInfo.id;
                    self.$store.state.sessions[cid] = newSession;
                    this.emit('sessionUpdated');
                });
        } else {
            return;
        }
    }
    sessions() {
        return this.$store.state.sessions;
    }
    openRemoteDevToolsURL(options, retries = 0) {
        let self = this,
            { caller, item, modifier } = options;
        let jsonInfoURL = `${process.env.VUE_APP_API_SERVER}/json/${item.tunnelSocket.cid}`;
        caller.$set(caller.loading, item.tunnelSocket.cid, true);
        return axiosCache
            .get(jsonInfoURL, {
                headers: {
                    Authorization: `Bearer ${caller.token}`
                }
            })
            .then(response => {
                let inspectorId = response.data[0].id;

                caller.$set(caller.jsonInfo, item.tunnelSocket.cid, response.data[0]);
                let url = `https://chrome-devtools-frontend.brakecode.com/inspector.html?experiments=true&v8only=true&wss=${process.env.VUE_APP_API_SERVER.match(/https:\/\/(.*)/)[1]}/ws/${item.tunnelSocket.cid}/${inspectorId}?runtime=${item.name.match(/deno/) ? 'deno' : 'node'}`;

                if (modifier) {
                    caller.inspectorWindows[item.tunnelSocket.cid] = window.open(url, "_blank");
                }
                self.addSession({
                    src: url,
                    cid: item.tunnelSocket.cid,
                    inspectorId,
                    stub: modifier ? true : false,
                    item
                }, () => {
                    caller.$set(caller.loading, item.tunnelSocket.cid, false);
                    if (!modifier) caller.$router.push({
                        path: `debugger?cid=${item.tunnelSocket.cid}`
                    });
                });
            })
            .catch(error => {
                if (retries >= maxRetries) {
                    caller.$set(caller.loading, item.tunnelSocket.cid, false);
                    caller.$set(caller.alert, item.tunnelSocket.cid, true);
                    caller.$set(caller.xhrStatus, item.tunnelSocket.cid, error.message);
                    console.log(error);
                } else {
                    setTimeout(() => {
                        console.log(`Retrying (attempt ${retries} of ${maxRetries}) remote devtools url...`);
                        self.openRemoteDevToolsURL({caller, item, modifier}, ++retries);
                    }, retryInterval * retries);
                }
            });
    }
}

export default (Vue) => new DebuggerService(Vue);