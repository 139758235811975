import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';

import { Auth0Plugin } from "./auth";
import BackendPlugin from './plugins/backend';
import DebuggerPlugin from './plugins/debugger';

import UUID from 'vue-uuid';
import AsyncComputed from 'vue-async-computed';
import createPersistedState from "vuex-persistedstate";
import { DateTime } from 'luxon';
Object.defineProperty(Vue.prototype, '$luxon', { value: { DateTime } });

let domain = process.env.VUE_APP_AUTH0_DOMAIN,
    clientId = process.env.VUE_APP_AUTH0_CLIENTID,
    audience = process.env.VUE_APP_AUTH0_AUDIENCE;
// Install the authentication plugin here

Vue.use(Vuex);
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    }
});
Vue.use(BackendPlugin);
Vue.use(DebuggerPlugin);
Vue.use(UUID);
Vue.use(AsyncComputed);

Vue.config.productionTip = false;
const store = new Vuex.Store({
    state: {
        sessions: {},
        github: {
            user: '',
            repos: [],
            token: {}
        },
        dashboard: {
            activePanels: []
        },
        sunray: {
            workstations: [],
            workstationLogs: {},
            refreshPaused: false
        },
        loading: {
            workstationImport: false,
            removeTaintedWorkstation: {},
            removeTaintedWorkstationImage: {},
            restoreWorkstation: {},
            suspendWorkstation: {}
        },
        pause: false
    },
    plugins: [createPersistedState()],
    mutations: {
        addSession: (state, payload) => {
            Object.assign(state.sessions, payload);
        },
        removeSession: (state, session) => {
            delete state.sessions[session.cid]
        },
        updateSession: session => { session },
        updateGitHubUser: (state, user) => { state.github.user = user },
        addRepos: (state, repos) => { state.github.repos = repos },
        updateRepos: (state, repos) => {
            repos.map(repo => {
                let index = state.github.repos.findIndex(stateRepo => stateRepo.name === repo.name);
                if (index != -1) {
                    state.github.repos[index].hacktoberfest = repo.hacktoberfest;
                    state.github.repos[index].topics = repo.topics;
                }
            });
        },
        updateGitHubAuth: (state, token) => { state.github.token = token },
        activeDashboardPanels: (state, panelIndex) => { state.dashboard.activePanels = panelIndex },
        updateLoadingState_workstationImport: (state, loading) => { state.loading.workstationImport = loading },
        updateLoadingState_removeTaintedWorkstation: (state, loading) => { Vue.set(state.loading.removeTaintedWorkstation, loading.name, loading.value) },
        updateLoadingState_removeTaintedWorkstationImage: (state, loading) => { Vue.set(state.loading.removeTaintedWorkstationImage, loading.name, loading.value) },
        updateLoadingState_suspendWorkstation: (state, loading) => { Vue.set(state.loading.suspendWorkstation, loading.name, loading.value) },
        updateLoadingState_restoreWorkstation: (state, loading) => { Vue.set(state.loading.restoreWorkstation, loading.name, loading.value) },
        refreshPaused: (state, paused) => { state.sunray.refreshPaused = paused },
        updateWorkstations: (state, data) => { Vue.set(state.sunray, 'workstations', data) },
        updateWorkstationLogs: (state, log) => { Vue.set(state.sunray.workstationLogs, log.name, log.value) }
    }
});

let $vm = new Vue({
    store,
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')

$vm.$debugger.setStore(store);