import Vue from 'vue'
import Router from 'vue-router'
import { authGuard } from "./auth";

Vue.use(Router)

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('./views/Home.vue')
        },
        {
            path: '/callback',
            name: 'callback',
            component: () => import('./components/Callback.vue')
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('./views/Dashboard.vue'),
            beforeEnter: authGuard

        },
        {
            path: '/debugger',
            name: 'debugger',
            component: () => import('./views/DebuggerUI.vue'),
            beforeEnter: authGuard

        },
        {
            path: '/synchook',
            name: 'synchook',
            component: () => import('./views/Synchook.vue')

        },
        {
            path: '/hacktoberfest',
            name: 'hacktoberfest',
            component: () => import('./views/Hacktoberfest.vue')
        },
        {
            path: '*',
            name: 'catchall',
            component: () => import('./views/Home.vue')
        },
    ]
});

export default router;
