<template>
  <v-snackbar
    v-model="snackbar"
    :color="color"
    :timeout="timeout"
    top
  >
    <v-icon large color="black" class="mr-4">mdi-alert-decagram</v-icon>
    <v-card class="my-4" color="black" outlined>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline font-weight-bold mb-4">{{message.split(':')[0]}}</div>
        <span class="font-weight-light">{{message.split(':')[1]}}</span>
      </v-list-item-content>
    </v-list-item>

    <v-card-actions>
      <v-btn outlined text color="white" x-small @click="login()">Retry Login</v-btn>
      <v-btn outlined text color="white" x-small @click="loginFresh()">Use another account</v-btn>
    </v-card-actions>
  </v-card>
    <v-btn text dark @click="snackbar = false">
      <span class="title font-weight-light black--text">x</span>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: "Error",
  data() {
    return {
      color: '#ffeb3b',
      timeout: -1,
      snackbar: false
    }
  },
  computed: {
    message: function() {
      return this.$auth.error ? this.$auth.error.message : '';
    }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect({ appState: { targetUrl: '/dashboard' } });
    },
    loginFresh() {
      this.$auth.loginWithRedirect({ prompt: 'login', appState: { targetUrl: '/dashboard' } });
    }
  },
  watch: {
    message: function(o) {
      this.snackbar = o ? true : false;
    }
  }
};
</script>