import axios from "axios";
import io from 'socket.io-client'; 
import { EventEmitter } from "events";
import { v5 as uuidv5 } from 'uuid';
import * as nacl from 'tweetnacl';
import * as util from 'tweetnacl-util';

import Sunray from './plugins/sunray';

nacl.util = util;

const axiosCache = axios.create({
    headers: { "Cache-Control": "no-cache" }
});

axiosCache.defaults.raxConfig = {
    instance: axiosCache
};

class BackendService extends EventEmitter {
    constructor(auth) {
        super();
        this.axiosCache = axiosCache;
        this.$auth = auth;
        this.dashboard;
        this.io;
        this.sunray = new Sunray(this);
    }
    encryptMessage(message) {
        let clientPrivateKey = nacl.randomBytes(32),
            publicKey = nacl.util.decodeBase64(process.env.VUE_APP_PUBLIC_KEY),
            nonce = crypto.getRandomValues(new Uint8Array(24)),
            keyPair = nacl.box.keyPair.fromSecretKey(clientPrivateKey);
        message = nacl.util.decodeUTF8(JSON.stringify(message));
        let encryptedMessage = nacl.box(message, nonce, publicKey, keyPair.secretKey);
        /**
         * let keyPair2 = nacl.box.keyPair.fromSecretKey(nacl.util.decodeBase64('other sides secret key'));
         * let decryptedMessage = nacl.box.open(encryptedMessage, nonce, keyPair.publicKey, keyPair2.secretKey);
         * console.log(`encrypted: ${nacl.util.encodeBase64(keyPair.publicKey)} ${nacl.util.encodeBase64(encryptedMessage)}`);
         * console.log(`decrypted: ${nacl.util.encodeUTF8(decryptedMessage)}`);
         * 
         */
        return nacl.util.encodeBase64(nonce) + ' ' + nacl.util.encodeBase64(keyPair.publicKey) + ' ' + nacl.util.encodeBase64(encryptedMessage);
    }
    startSocket(apikey) {
        return new Promise(async (resolve, reject) => {
            let self = this;
            let apikeyHashedUUID = await this.getHashedAPIKey(apikey);
            let namespace = apikeyHashedUUID;

            self.io = io(process.env.VUE_APP_API_SERVER + '/' + namespace, {
                query: { apikey: self.encryptMessage(apikey) },
                transports: ['websocket'],
                path: '/dashboard'
            })
            .on('connect_error', (error) => {
                console.log('CALLBACK ERROR: ' + error);
                reject(error);
            })
            .on('error', reason => {
                console.log(reason);
                reject(reason);
            })
            resolve(self.io);
        });
    }
    getHashedAPIKey(apikey) {
        return new Promise((resolve, reject) => {
            axiosCache
                .get(`https://cloudflare-dns.com/dns-query?name=${process.env.VUE_APP_NAMESPACE_APIKEY_NAME}&type=TXT`, {
                    headers: {
                        accept: 'application/dns-json'
                    }
                })
                .then(response => {
                    if (response.status !== 200) return reject(response.statusText);
                    if (response.data.Status !== 0) return reject(new Error(`Cloudflare query failed with status code: ${response.data.Status}`));
                    let namespaceUUID = response.data.Answer[0].data;
                    if (!namespaceUUID) {
                        reject(new Error(`Error getting ${process.env.VUE_APP_NAMESPACE_APIKEY_NAME}.`));
                    }
                    namespaceUUID = namespaceUUID.replace(/"/g, '');
                    resolve(uuidv5(apikey, namespaceUUID));
                });
        });
    }
    getIO() {
        return this.io;
    }
    async getDashboard() {
        let token = await this.$auth.getTokenSilently();

        return new Promise(resolve => {
            axiosCache
                .get(process.env.VUE_APP_API_SERVER + '/api/v1/dashboard', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    resolve(response.data);
                });
        });
    }
    async rollAPIKey() {
        let token = await this.$auth.getTokenSilently();
        return new Promise(resolve => {
            axiosCache
                .post(process.env.VUE_APP_API_SERVER + '/api/v1/vault/apikey', {}, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    resolve(response.data.apikey);
                });
        });
    }
    async getHistory() {
        let token = await this.$auth.getTokenSilently();
        return new Promise(resolve => {
            axiosCache
                .get(process.env.VUE_APP_API_SERVER + '/api/v1/report/history', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    resolve(response.data);
                });
        });
    }
    async getRules() {
        let token = await this.$auth.getTokenSilently();
        return new Promise(resolve => {
            axiosCache
                .get(process.env.VUE_APP_API_SERVER + '/api/v1/rtk/rules', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    response.data.map(key => {
                        if (key.id == 'library-mismatch') {
                            key.meta.schema.type = 'vcombobox';
                            key.meta.schema.format = 'vcombobox';
                            key.meta.schema.name = 'ignore';
                        }
                        return key;
                    });
                    resolve(response.data);
                });
        });
    }
    async updateRules(rules) {
        let token = await this.$auth.getTokenSilently();
        return new Promise((resolve, reject) => {
            axiosCache
                .post(process.env.VUE_APP_API_SERVER + '/api/v1/rtk/rules', {
                    rules
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                })
        });
    }
    async getRepos(params) {
        return new Promise((resolve, reject) => {
            axiosCache.get(process.env.VUE_APP_API_SERVER + '/api/v1/hacktoberfest/getRepos', {
                params
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
        });
    }
    async getAuth(params) {
        return new Promise((resolve, reject) => {
            axiosCache.get(process.env.VUE_APP_API_SERVER + '/api/v1/hacktoberfest/getAuth', {
                params
            })
            .then(response => {
                resolve(response);
                document.cookie = `__secure-session-hacktoberfestify=${JSON.stringify({ access_token: response.access_token })}; Domain=brakecode.com; SameSite=None; Secure;`
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
        });
    }
    async toggle(token, data) {
        return new Promise((resolve, reject) => {
            axiosCache.post(process.env.VUE_APP_API_SERVER + '/api/v1/hacktoberfest/toggle', {
                data
            },{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
        });
    }
    async getShareLink(params) {
        let token = await this.$auth.getTokenSilently();
        return new Promise((resolve, reject) => {
            axiosCache.post(process.env.VUE_APP_API_SERVER + '/api/v1/generateShareLink', {
                ...params
            },{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
        });
    }
}

export default (auth) => new BackendService(auth);
