<template>
    <v-footer color="grey darken-3" padless>
        <v-row no-gutters>
            <v-col align-self="center" class="d-flex justify-center" :cols="$vuetify.breakpoint.mobile ? 12 : 10">
                <v-btn v-for="link in links" :href="link.href" :key="link.name" :x-small="$vuetify.breakpoint.mobile" color="white" text rounded class="font-weight-light text-xs-caption text-xl-subtitle-1 my-2">{{ link.name }}</v-btn>
                <v-btn @click="enableCanary" v-if="!isCanary" :x-small="$vuetify.breakpoint.mobile" color="white" text rounded class="font-weight-light text-xs-caption text-xl-subtitle-1 my-2">canary</v-btn>
            </v-col>
            <v-col class="grey darken-2 py-4 text-center white--text">
                <v-icon small color="white">mdi-copyright</v-icon>
                <span class="font-weight-light">
                    <span class="font-weight-thin body-2 mr-2">{{ new Date().getFullYear() }}</span>
                    <StylizedName />
                </span>
            </v-col>
        </v-row>
        <v-bottom-sheet :value="isCanary" persistent hide-overlay no-click-animation>
            <v-row>
                <v-col class="d-flex justify-center" :class="{ 'mb-14': !$vuetify.breakpoint.mobile }" cols="12">
                    <v-icon color="yellow darken-2" :x-small="$vuetify.breakpoint.mobile">fa-exclamation-circle</v-icon>
                    <div v-if="!$vuetify.breakpoint.mobile" style="align-self: center" class="mr-6 ml-2 font-italic">You are using a canary version of the BrakeCODE dashboard.</div>
                    <div v-else style="align-self: center" class="mr-6 ml-2 font-italic">Canary version</div>
                    <v-btn @click="disableCanary" :x-small="$vuetify.breakpoint.mobile" plain class="font-weight-light text-xs-caption text-xl-subtitle-1 my-2">disable</v-btn>
                </v-col>
            </v-row>
        </v-bottom-sheet>
    </v-footer>
</template>
<style>
</style>
<script>
import StylizedName from "./StylizedName";

export default {
    name: "NavFooter",
    data() {
        return {
            canarySwitch: this.isCanary,
            isAuthenticated: false,
            profile: this.$auth.profile,
            links: [
                { name: "Blog", href: "https://blog.brakecode.com" },
                { name: "Privacy", href: "https://brakecode.com/privacy" },
                { name: "Terms", href: "https://brakecode.com/terms" },
                { name: "Contact", href: "mailto:support@brakecode.com" },
            ],
        };
    },
    components: {
        StylizedName,
    },
    methods: {
        enableCanary: function() {
            document.cookie = `__secure-canary=1; Domain=brakecode.com; SameSite=None; Secure; Expires=2147483647;`;
            this.$router.go();
        },
        disableCanary: function () {
            document.cookie = `__secure-canary=0; Domain=brakecode.com; SameSite=None; Secure; Expires=2147483647;`;
            this.$router.go();
        },
    },
    computed: {
        isCanary() {
            const re = /__secure-canary=(.*)/;
            const canaryCookieValue = document.cookie.split(";").find((cookie) => cookie.trim().match(re))?.match(re)[1] || 0;
            return canaryCookieValue != 0;
        },
    },

};
</script>

